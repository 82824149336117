@charset "utf-8";
/* TOC – Typography variables

Modular Scale › http://www.modularscale.com//?16,36&px&1.25&web&table

- Fonts
- Font Weight
- Font Size Variables

*/

@import "functions"; // Allows the use of rem-calc() or lower-bound() in your settings

@font-face {
  font-family: "LucidaMine";
  src: url("../fonts/LucidaConsoleV2.woff");
}
@font-face {
    font-family: 'Roboto';
    src:  url('../fonts/Roboto-Regular-webfont.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: 'Roboto';
    src: url('../fonts/Roboto-Italic-webfont.woff') format('woff');
    font-weight: 400;
    font-style: italic;
}
@font-face {
    font-family: 'Roboto';
    src: url('../fonts/Roboto-Bold-webfont.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}
@font-face {
    font-family: 'Roboto';
    src: url('../fonts/Roboto-BoldItalic-webfont.woff') format('woff');
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: 'Roboto Condensed';
    src: url('../fonts/Roboto-Condensed-webfont.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: 'Roboto Condensed';
    src: url('../fonts/Roboto-CondensedItalic-webfont.woff') format('woff');
    font-weight: 400;
    font-style: italic;
}
@font-face {
    font-family: 'Roboto Condensed';
    src: url('../fonts/Roboto-BoldCondensed-webfont.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}
@font-face {
    font-family: 'Roboto Condensed';
    src: url('../fonts/Roboto-BoldCondensedItalic-webfont.woff') format('woff');
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: 'Roboto Thin';
    src: url('../fonts/Roboto-Thin-webfont.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: 'Roboto Thin';
    src: url('../fonts/Roboto-ThinItalic-webfont.woff') format('woff');
    font-weight: 400;
    font-style: italic;

}

@font-face {
    font-family: 'Roboto Light';
    src: url('../fonts/Roboto-Light-webfont.woff') format('woff');
    font-weight: 400;
    font-style: normal;

}
@font-face {
    font-family: 'Roboto Light';
    src: url('../fonts/Roboto-LightItalic-webfont.woff') format('woff');
    font-weight: 400;
    font-style: italic;

}

@font-face {
    font-family: 'Roboto Medium';
    src: url('../fonts/Roboto-Medium-webfont.woff') format('woff');
    font-weight: 400;
    font-style: normal;

}
@font-face {
    font-family: 'Roboto Medium';
    src: url('../fonts/Roboto-MediumItalic-webfont.woff') format('woff');
    font-weight: 400;
    font-style: italic;

}

@font-face {
    font-family: 'Roboto Black';
    src: url('../fonts/Roboto-Black-webfont.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: 'Roboto Black';
    src: url('../fonts/Roboto-BlackItalic-webfont.woff') format('woff');
    font-weight: 400;
    font-style: italic;
}

/* Fonts
------------------------------------------------------------------- */

$base-font-size: 17.6px;
$rem-base: $base-font-size;
// $base-line-height is 24px while $base-font-size is 16px
$base-line-height: 1.5 !default;


$font-family-sans-serif: "Roboto", Helvetica, Arial, sans-serif;
$font-family-serif: Georgia, Times, serif;
//$font-family-sans-serif: "Lato", "Helvetica Neue", Helvetica, Arial, sans-serif;
//$font-family-serif: "Volkhov", Georgia, Times, serif;
$font-family-monospace: "LucidaMine", Monaco, monospace;

$body-font-family: $font-family-sans-serif;
$body-font-weight: normal;
$body-font-style: normal;

$header-font-family: $font-family-sans-serif;



/* Font Weight
------------------------------------------------------------------- */

$font-weight-normal: normal;
$font-weight-bold: bold;



/* Font Size Variables
------------------------------------------------------------------- */

$font-size-p:    	$base-font-size;
$font-size-h1:      2.441em;
$font-size-h2:      1.953em;
$font-size-h3:      1.563em;
$font-size-h4:      1.25em;
$font-size-h5:      1.152em;
$font-size-small:   0.8em;

.font-size-h1   { font-size: $font-size-h1; }
.font-size-h2   { font-size: $font-size-h2; }
.font-size-h3   { font-size: $font-size-h3; }
.font-size-h4   { font-size: $font-size-h4; }
.font-size-h5   { font-size: $font-size-h5; }
